import { HttpClientModule } from '@angular/common/http';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';

import { ToastModule } from 'primeng/toast';
import { StorageService } from './core/services/storage/storage.service';
import { environment } from 'src/environments/environment.development';
/**
 * The app component
 */
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  /**
   * AuthService injection
   */
  #auth0 = inject(AuthService);
  /**
   * Router injection
   */
  #router = inject(Router);
  /**
   * StorageService injection
   */
  #storageService = inject(StorageService);
  /**
   * Method that runs when the app component is initialized and validates whether the user is logged in to Okta
   * @returns void
   */
  ngOnInit(): void {
    this.#auth0.isAuthenticated$.subscribe((_response) =>
      _response ? this.#setUserData() : this.#auth0.loginWithRedirect()
    );
  }
  /**
   * It obtains the authenticated user's information and saves it in the browser's storage and redirects to the chat
   * @returns void
   */
  #setUserData(): void {
    this.#auth0.user$.subscribe((_response) => {
      // console.log(_response);
      if (
        !environment.production &&
        !(_response!['perfiles'] as string[]).includes(environment.profile)
      ) {
        this.#router.navigateByUrl('/error');
        return;
      }
      this.#storageService.set('user', _response);
      this.#router.navigateByUrl('/chat');
    });
  }
  /**
   * Detects when the user copies information from the app and removes any styles and formatting it contains
   * @param {ClipboardEvent} event object with the structure of the ClipboardEvent interface
   * @returns void
   */
  @HostListener('document:copy', ['$event'])
  handleCopy(event: ClipboardEvent): void {
    event.preventDefault();
    const selection = document.getSelection();
    if (!selection) return;
    event.clipboardData?.setData('text/plain', selection.toString());
  }
}
