import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
// import { sessionGuard } from './core/guards/session/session.guard';

export const routes: Routes = [
  // {
  //   path: '',
  //   loadComponent: () => import('./pages/login/login.component')
  // },
  {
    path: 'chat',
    loadComponent: () => import('./pages/main/main.component'),
    // canActivate: [sessionGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/chat/chat.module'),
        // canActivate: [sessionGuard],
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/error/error.component')
  }
];
